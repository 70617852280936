import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAJECTORIES_SIMULATION_EQUALITY",
  title: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.title",
    defaults: `Simulations that achieve equality between children from rich and poor families have a limited impact on learning in low- and lower-middle-income countries`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.caption",
    defaults: `Descriptive and simulated learning trajectories for 23 low- and lower-middle-income countries showing the percentage of children (aged 7–14 years) who have mastered foundational skills, typically at the Grade 2–3 level. This is below the minimum proficiency level defined by SDG global indicator 4.1.1. Trajectories by age include all children (whether they were in school at the time of the survey, had dropped out or had never attended school). In this graph, the equal access simulation line does not continue after age 11 due to missing data from ages 12 to 14 years.`,
  },
  source: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_EQUALITY.source",
    defaults: `Analysis by RISE based on United Nations Children’s Fund (UNICEF) Multiple Indicator Cluster Survey (MICS6) data.`,
  },
};
