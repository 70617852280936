import css from "@emotion/css";
import { nanoid } from "nanoid";
import React from "react";
import * as M from "../materials";
import { FigureControlItem } from "../domain";

export interface Option {
  value: string;
  label: string;
}

const MultiCheckbox = ({
  title,
  items,
  selectedItems,
  onChange,
}: {
  title?: string;
  items: Array<FigureControlItem>;
  selectedItems: string[];
  onChange: (x: Array<FigureControlItem> | null) => void;
}) => {
  const id = `checkbox-form-${nanoid(5)}`;

  const handler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value;
    if (selectedItems.includes(changedValue)) {
      onChange(
        items.filter(
          ({ value }) => selectedItems.includes(value) && value !== changedValue
        )
      );
    } else {
      const selection = items.filter(
        ({ value }) => selectedItems.includes(value) || value === changedValue
      );
      onChange(selection);
    }
  };

  return (
    <>
      {title ? (
        <h4
          css={css`
            ${M.fontHeading4}
            margin-bottom: ${M.spacing.base8(0.5)};
          `}
        >
          {title}
        </h4>
      ) : null}
      <div
        css={css`
          background-color: ${M.grayscalePalette[2]};
          border: 1px solid ${M.grayscalePalette[4]};
          border-radius: ${M.spacing.base8(0.5)};
          ${M.fontChartGroupTitle}
          margin-bottom: ${M.spacing.base8(2)}
        `}
      >
        <div>
          {items.map(({ value, label, Component: OptionComponent }) => {
            const isSelected = selectedItems.includes(value);
            const Component = (OptionComponent ||
              MultiCheckboxItem) as typeof MultiCheckboxItem;
            return (
              <Component
                key={value}
                value={value}
                isSelected={isSelected}
                id={id}
                handler={handler}
                label={label}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MultiCheckbox;

function MultiCheckboxItem({
  value,
  isSelected,
  id,
  handler,
  label,
}: {
  value: string;
  isSelected: boolean;
  id: string;
  handler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}): JSX.Element {
  return (
    <div
      key={value}
      css={css`
        width: 100%;
        display: flex;
        border-radius: ${M.spacing.base8(0.5)};
        padding: ${M.spacing.base8(0.5)} ${M.spacing.base8(1)};
        &:hover {
          background-color: ${M.grayscalePalette[3]};
        }
      `}
    >
      <input
        type="checkbox"
        id={`checkbox-${value}`}
        value={value}
        checked={isSelected}
        name={id}
        onChange={handler}
      />
      <label
        htmlFor={`checkbox-${value}`}
        css={css`
          width: 100%;
          margin-left: ${M.spacing.base8(1)};
          margin-right: ${M.spacing.base8(1)};
          color: ${isSelected ? M.unescoMarineBlue : M.blackText};
        `}
      >
        {label}
      </label>
    </div>
  );
}
