import { MessageDescriptor } from "@lingui/core";
import { t } from "@lingui/macro";
import { Trans } from "@lingui/react";
import { format } from "d3-format";
import { motion } from "framer-motion";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TrajectoriesChart } from "charts/TrajectoriesChart";
import { useConfig } from "config";
import { everything, SKILL } from "lib/explorer-gql/generated";
import { useI18n } from "locales";
import { withFigureIOExplorer } from "../components/figure";
import { metadata } from "../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER_SIMULATION";
import {
  comparisonCountryControlTitle,
  countriesSimulationControlItems,
  COUNTRIES_EXCLUDED_DISPARITY_SIM,
  countryControlPlaceholder,
  countryControlTitle,
  CountryId,
  countryIdIso,
  disparitiesSimulationControlItems,
  DisparityCategory,
  disparityGroupsControlItems,
  disparitySimulationLookup,
  explorerAPI,
  ExplorerContext,
  getRegionOrCountryName,
  goalRule,
  GroupLabelLookup,
  makeUseControl,
  RegionId,
  RenderMode,
  RiseDataDecoder,
  Series,
  simulationControlsLabels,
  useFigureState,
  verticalAxisControlItems,
  verticalAxisControlLabel,
  View,
  viewControlItems,
  xAxisSubtitleLookup,
  xAxisTitleLookup,
  yAxisTitleLookup,
} from "../domain";
import * as M from "../materials";
import { E, io, O, pipe } from "../prelude";
import { colors, makeLineStyler } from "./TRAJECTORIES_SIMULATION_LMIC";
import Carousel from "../components/carousel";
import { useFetch } from "hooks/useFetch";
import { Popup } from "components/popup";

import {
  EmptyInterpretation,
  ToggleInterpretationButton,
  CarouselFooter,
  defaultValues,
  formatPerc,
  Highlight,
  i18nLookup,
  InterpretationToken,
  InterpretationSlideProps,
  makeDisparityGroupLabels,
  SlideTitle,
  Slide,
  formatPercentagePoints,
} from "../components/interpretation";
import { Button } from "components/button";
import { css } from "@emotion/core";

export * from "../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER_SIMULATION";

export const Data = RiseDataDecoder(
  ["id", "value", "country", "year", "group", "series", "facet"],
  {}
);
export type Data = io.TypeOf<typeof Data>;

enum Controls {
  "2-toggle-interpretation" = "2-toggle-interpretation",
  "0-vertical-axis" = "0-vertical-axis",
  "1-simulations-countries" = "1-simulations-countries",
  "1-simulations-disparities" = "1-simulations-disparities",
  "0-view" = "0-view",
  "1-country" = "1-country",
  "2-groups" = "2-groups",
  "2-comparison-country" = "2-comparison-country",
}

type COUNTRY_SIMULATION = "SIMULATION_ACCESS" | "SIMULATION_LEARNING";
const COUNTRY_SIMULATIONS_LEGEND = [
  "SIMULATION_LEARNING",
  "SIMULATION_ACCESS",
] as COUNTRY_SIMULATION[];

const useControl = makeUseControl({
  "2-toggle-interpretation": {
    control: "MultiCheckbox",
    defaultValue: [],
  },
  "1-country": {
    control: "SingleSelect",
    defaultValue: "",
  },
  "2-groups": {
    control: "Radio",
    defaultValue: "WEALTH" as DisparityCategory,
  },
  "2-comparison-country": {
    control: "SingleSelect",
    defaultValue: "",
  },
  "1-simulations-countries": {
    control: "MultiCheckbox",
    defaultValue: [] as COUNTRY_SIMULATION[],
  },
  "1-simulations-disparities": {
    control: "MultiCheckbox",
    defaultValue: [] as string[],
  },
  "0-vertical-axis": {
    defaultValue: "LIT" as SKILL,
    control: "Radio",
  },
  "0-view": {
    defaultValue: "countries" as View,
    control: "Radio",
  },
});

const ActivateSimulationSlide = ({
  onActivate,
}: {
  onActivate: () => void;
}) => {
  return (
    <>
      <Trans id="fig.learning.trajectories.interpretation.simulation.simulation-deactivated">
        Activate the simulation to see the interpretation.
      </Trans>
      <Button
        css={css`
          margin: 0.5rem 0;
        `}
        rounded
        inverted
        onClick={() => onActivate()}
      >
        Activate
      </Button>
    </>
  );
};

const InterpretationSimulationCountries = ({
  dataset,
  getCountryName,
  chartActions,
  requestClose,
}: InterpretationSlideProps & {
  chartActions: Exclude<InterpretationSlideProps["chartActions"], undefined>;
}) => {
  const country_ = useControl("1-country");
  const skill = useControl("0-vertical-axis");

  const countryControl = countryIdIso.wrap(country_);
  const comparisonCountry_ = useControl("2-comparison-country");
  const comparisonCountryControl = countryIdIso.wrap(comparisonCountry_);

  const simulations = useControl("1-simulations-countries");
  const [, actions] = useFigureState();
  const handleActivate = (simName: COUNTRY_SIMULATION) => {
    actions.toggleMultiCheckboxItem("1-simulations-countries", simName);
  };

  const i18n = useI18n();

  const { setHighlightedSeries } = chartActions;

  const findPoint = (d: Data[number]) => d.year === 9;
  const age9Values = useMemo(() => {
    return Object.fromEntries(
      dataset.filter(findPoint).map((d) => [d.series as Series, d.value || 0])
    ) as Record<Series, Exclude<Data[number]["value"], null>>;
  }, [dataset]);

  const {
    comparisonCountry,
    country,
    age9ComparisonValue,
    age9CountryValue,
    countryDifference,
    // accessDifference,
    accessChange,
    accessName,
    learningChange,
    learningDifference,
    learningChangeAdverb,
    learningName,
    skillName,
    skillVerb,
  }: typeof defaultValues = useMemo(() => {
    const year9pointCurrent = dataset.find(
      (p) => findPoint(p) && p.series === "CURRENT"
    );
    const year9pointComparison = dataset.find(
      (p) => findPoint(p) && p.series === "COMPARISON"
    );
    const year9pointAccess = dataset.find(
      (p) => findPoint(p) && p.series === "SIMULATION_ACCESS"
    );
    const year9pointLearning = dataset.find(
      (p) => findPoint(p) && p.series === "SIMULATION_LEARNING"
    );
    const countryDifference =
      Math.round((age9Values.COMPARISON - age9Values.CURRENT) * 100) / 100;
    const learningDifference =
      Math.round((age9Values.SIMULATION_LEARNING - age9Values.CURRENT) * 100) /
      100;
    const accessSimDifference =
      Math.round((age9Values.SIMULATION_ACCESS - age9Values.CURRENT) * 100) /
      100;
    return {
      age9CountryValue: (
        <InterpretationToken
          color={colors.current}
          chartActions={chartActions}
          highlightPoint={year9pointCurrent}
          highlightSeries="CURRENT"
        >
          {formatPerc(age9Values.CURRENT)}
        </InterpretationToken>
      ),
      age9ComparisonValue: (
        <InterpretationToken
          color={colors.comparison}
          chartActions={chartActions}
          highlightPoint={year9pointComparison}
          highlightSeries="COMPARISON"
        >
          {formatPerc(age9Values.COMPARISON)}
        </InterpretationToken>
      ),
      country: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.current}
          highlightSeries="CURRENT"
        >
          {getCountryName(countryControl)}
        </InterpretationToken>
      ),
      comparisonCountry: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.comparison}
          highlightSeries="COMPARISON"
        >
          {getCountryName(comparisonCountryControl)}
        </InterpretationToken>
      ),
      countryDifference: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={year9pointCurrent}
        >
          {formatPercentagePoints(Math.abs(countryDifference), i18n)}
        </InterpretationToken>
      ),

      accessDifference: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={year9pointAccess}
          highlightSeries="SIMULATION_ACCESS"
          color={colors.accessSimulation}
        >
          {formatPercentagePoints(Math.abs(accessSimDifference), i18n)}
        </InterpretationToken>
      ),
      accessName: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.accessSimulation}
          highlightSeries="SIMULATION_ACCESS"
        >
          <Trans id="fig.learning.trajectories.interpretation.simulation.accessName">
            access simulation
          </Trans>
        </InterpretationToken>
      ),
      accessChange: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={year9pointAccess}
          highlightSeries="SIMULATION_ACCESS"
          color={
            Math.round(accessSimDifference * 100) !== 0
              ? colors.accessSimulation
              : undefined
          }
        >
          {Math.round(accessSimDifference * 100) === 0
            ? i18n._(i18nLookup.noChange)
            : accessSimDifference > 0
              ? i18n._(i18nLookup.increase)
              : i18n._(i18nLookup.decrease)}
        </InterpretationToken>
      ),

      learningName: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.learningSimulation}
          highlightSeries="SIMULATION_LEARNING"
        >
          <Trans id="fig.learning.trajectories.interpretation.simulation.learningName">
            learning simulation
          </Trans>
        </InterpretationToken>
      ),
      learningDifference: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={year9pointLearning}
          color={colors.learningSimulation}
          highlightSeries="SIMULATION_LEARNING"
        >
          {formatPercentagePoints(Math.abs(learningDifference), i18n)}
        </InterpretationToken>
      ),
      learningChange: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={year9pointLearning}
          highlightSeries="SIMULATION_LEARNING"
          color={
            Math.round(learningDifference * 100) !== 0
              ? colors.learningSimulation
              : undefined
          }
        >
          {Math.round(learningDifference * 100) === 0
            ? i18n._(i18nLookup.noChange)
            : learningDifference > 0
              ? i18n._(i18nLookup.increase)
              : i18n._(i18nLookup.decrease)}
        </InterpretationToken>
      ),
      learningChangeAdverb: (
        <>
          {Math.round(learningDifference * 100) === 0
            ? ""
            : learningDifference > 0
              ? i18n._(i18nLookup.greater)
              : i18n._(i18nLookup.lower)}
        </>
      ),
      skillName: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillName.LIT"])
            : i18n._(i18nLookup["skillName.NUM"])}
        </Highlight>
      ),
      skillVerb: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillVerb.LIT"])
            : i18n._(i18nLookup["skillVerb.NUM"])}
        </Highlight>
      ),
    } as const;
  }, [
    age9Values.COMPARISON,
    age9Values.CURRENT,
    age9Values.SIMULATION_ACCESS,
    age9Values.SIMULATION_LEARNING,
    chartActions,
    comparisonCountryControl,
    countryControl,
    dataset,
    getCountryName,
    i18n,
    skill,
  ]);

  if (
    !countryControl ||
    !comparisonCountryControl ||
    !age9Values.CURRENT ||
    !age9Values.COMPARISON ||
    dataset.length === 0
  ) {
    return null;
  }

  const handleSlideChange = (slideNumber: number) => {
    if (slideNumber === 0) {
      setHighlightedSeries("CURRENT");
      setTimeout(() => {
        setHighlightedSeries("COMPARISON");
        setTimeout(() => {
          setHighlightedSeries(undefined);
        }, 1000);
      }, 1000);
    }
    if (slideNumber === 1) {
      setHighlightedSeries("SIMULATION_ACCESS");
      setTimeout(() => setHighlightedSeries(undefined), 1000);
    } else if (slideNumber === 2) {
      setHighlightedSeries("SIMULATION_LEARNING");
      setTimeout(() => setHighlightedSeries(undefined), 1000);
    }
  };

  return (
    <Carousel
      FooterComponent={(footerProps) => (
        <CarouselFooter {...footerProps} onClose={requestClose} />
      )}
      onSlideChange={handleSlideChange}
    >
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.common.interpretation-title"
            )`Interpretation`
          )}
        </SlideTitle>
        <Trans id="fig.learning.trajectories.interpretation.simulation.interpretation-content">
          The blue trajectory shows the current rate of learning in {country}.
          The red trajectory shows the current rate of learning in the selected
          comparison country,
          {comparisonCountry}. SDG 4.1.1 expects all children to have acquired
          foundational {skillName} skills by Grade 3, which typically
          corresponds to age 9. Currently, about {age9CountryValue} of
          9-year-olds can {skillVerb} at an age-appropriate level in {country}{" "}
          and {age9ComparisonValue} of 9-year-olds can {skillVerb} at an
          age-appropriate level in {comparisonCountry}, a difference of about{" "}
          {countryDifference}.
        </Trans>
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.access-slide-title"
            )`Access simulation`
          )}
        </SlideTitle>
        {simulations.includes("SIMULATION_ACCESS") ? (
          <Trans id="fig.learning.trajectories.interpretation.simulation.access-content">
            The simulations estimate how much different policy priorities might
            close this gap. Access-oriented policies try to increase grade
            attainment (the grade that children reach in school). The
            {accessName} estimates what might happen if every out-of-school
            child in
            {country}
            attained the same grade, and associated learning level, as the
            average child their age who is still in school. In {country}, the
            access simulation results in {accessChange} in the percentage of
            9-year-olds who can {skillVerb} at the age-appropriate level.
          </Trans>
        ) : (
          <ActivateSimulationSlide
            onActivate={() => handleActivate("SIMULATION_ACCESS")}
          />
        )}
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.learning-slide-title"
            )`Learning simulation`
          )}
        </SlideTitle>
        {simulations.includes("SIMULATION_LEARNING") ? (
          <Trans id="fig.learning.trajectories.interpretation.simulation.learning-content">
            Learning-oriented policies try to increase the rate of learning per
            grade. The {learningName}
            estimates what might happen if access in {country} remained
            unchanged, but children learned as much per grade as their peers in{" "}
            {comparisonCountry}. This might result in {learningChange}
            in the percentage of 9-year-olds who can {skillVerb} at the
            age-appropriate level in {country}. The learning simulation results
            in about {learningDifference} {learningChangeAdverb}
            estimated learning gains than the access simulation at age 9.
          </Trans>
        ) : (
          <ActivateSimulationSlide
            onActivate={() => handleActivate("SIMULATION_LEARNING")}
          />
        )}
      </Slide>
    </Carousel>
  );
};

const InterpretationSimulationDisparities = ({
  dataset,
  getCountryName,
  chartActions,
  requestClose,
}: InterpretationSlideProps & {
  chartActions: Exclude<InterpretationSlideProps["chartActions"], undefined>;
}) => {
  const country_ = useControl("1-country");
  const skill = useControl("0-vertical-axis");
  const group = useControl("2-groups");
  const { setHighlightedSeries } = chartActions;

  const [, actions] = useFigureState();
  const simulations = useControl("1-simulations-disparities");
  const handleActivate = (simName: string) => {
    actions.toggleMultiCheckboxItem("1-simulations-disparities", simName);
  };
  const countryControl = countryIdIso.wrap(country_);
  const comparisonGroup_ = useControl("2-groups");
  const comparisonGroupControl = countryIdIso.wrap(comparisonGroup_);
  const i18n = useI18n();
  const age9Values = useMemo(() => {
    return Object.fromEntries(
      dataset
        .filter((d) => d.year === 9)
        .map((d) => [d.series as Series, d.value || 0])
    ) as Record<Series, Exclude<Data[number]["value"], null>>;
  }, [dataset]);

  const disparityGroupLabels = useMemo(
    () => makeDisparityGroupLabels(i18n),
    [i18n]
  );

  const {
    country,
    age9ComparisonValue,
    age9CountryValue,
    groupDifference,
    groupDifferenceAdjective,
    groupDifferenceAdverb,
    accessDifference,
    accessChange,
    accessName,
    learningChange,
    learningDifference,
    learningName,
    skillVerb,
    skillName,
    groupLeft,
    groupRight,
    rightGroupMinGoalDifference,
  } = useMemo(() => {
    const age9pointCurrent = dataset.find(
      (x) => x.year === 9 && x.series === "CURRENT"
    );
    const age9pointComparison = dataset.find(
      (x) => x.year === 9 && x.series === "COMPARISON"
    );
    const age9pointAccess = dataset.find(
      (x) => x.year === 9 && x.series === "SIMULATION_ACCESS"
    );
    const age9pointLearning = dataset.find(
      (x) => x.year === 9 && x.series === "SIMULATION_LEARNING"
    );
    const groupDifference =
      Math.round((age9Values.COMPARISON - age9Values.CURRENT) * 100) / 100;
    const learningDifference =
      Math.round((age9Values.SIMULATION_LEARNING - age9Values.CURRENT) * 100) /
      100;
    const accessSimDifference =
      Math.round((age9Values.SIMULATION_ACCESS - age9Values.CURRENT) * 100) /
      100;
    return {
      age9CountryValue: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.current}
          highlightPoint={age9pointCurrent}
          highlightSeries="CURRENT"
        >
          {formatPerc(age9Values.CURRENT)}
        </InterpretationToken>
      ),
      age9ComparisonValue: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.comparison}
          highlightPoint={age9pointComparison}
          highlightSeries="COMPARISON"
        >
          {formatPerc(age9Values.COMPARISON)}
        </InterpretationToken>
      ),
      country: <Highlight>{getCountryName(countryControl)}</Highlight>,
      comparisonCountry: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.comparison}
          highlightSeries="COMPARISON"
        >
          {getCountryName(comparisonGroupControl)}
        </InterpretationToken>
      ),
      groupDifference: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={age9pointCurrent}
        >
          {formatPercentagePoints(Math.abs(groupDifference), i18n)}
        </InterpretationToken>
      ),
      groupDifferenceAdjective:
        groupDifference > 0
          ? i18n._(i18nLookup.higher)
          : i18n._(i18nLookup.lower),
      groupDifferenceAdverb:
        groupDifference > 0 ? i18n._(i18nLookup.more) : i18n._(i18nLookup.less),

      accessDifference: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.accessSimulation}
          highlightPoint={age9pointAccess}
          highlightSeries="SIMULATION_ACCESS"
        >
          {formatPercentagePoints(Math.abs(accessSimDifference), i18n)}
        </InterpretationToken>
      ),
      accessName: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.accessSimulation}
          highlightSeries="SIMULATION_ACCESS"
        >
          <Trans id="fig.learning.trajectories.interpretation.simulation.accessName">
            access simulation
          </Trans>
        </InterpretationToken>
      ),
      accessChange: (
        <Highlight>
          {Math.floor(accessSimDifference * 100) === 0
            ? i18n._(i18nLookup.noChange)
            : accessSimDifference > 0
              ? i18n._(i18nLookup.increase)
              : i18n._(i18nLookup.decrease)}
        </Highlight>
      ),

      learningName: (
        <InterpretationToken
          color={colors.learningSimulation}
          highlightSeries="SIMULATION_LEARNING"
          chartActions={chartActions}
        >
          <Trans id="fig.learning.trajectories.interpretation.simulation.learningName">
            learning simulation
          </Trans>
        </InterpretationToken>
      ),
      learningDifference: (
        <InterpretationToken
          chartActions={chartActions}
          color={colors.learningSimulation}
          highlightSeries="SIMULATION_LEARNING"
          highlightPoint={age9pointLearning}
        >
          {formatPercentagePoints(Math.abs(learningDifference), i18n)}
        </InterpretationToken>
      ),
      learningChange: (
        <Highlight>
          {learningDifference === 0
            ? i18n._(i18nLookup.noChange)
            : learningDifference > 0
              ? i18n._(i18nLookup.increase)
              : i18n._(i18nLookup.decrease)}
        </Highlight>
      ),
      skillName: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillName.LIT"])
            : i18n._(i18nLookup["skillName.NUM"])}
        </Highlight>
      ),
      skillVerb: (
        <Highlight>
          {skill === "LIT"
            ? i18n._(i18nLookup["skillVerb.LIT"])
            : i18n._(i18nLookup["skillVerb.NUM"])}
        </Highlight>
      ),
      groupLeft: (
        <InterpretationToken
          chartActions={chartActions}
          highlightSeries="CURRENT"
          color={colors.current}
        >
          {disparityGroupLabels[group][0]}
        </InterpretationToken>
      ),
      groupRight: (
        <InterpretationToken
          chartActions={chartActions}
          highlightSeries="COMPARISON"
          color={colors.comparison}
        >
          {disparityGroupLabels[group][1]}
        </InterpretationToken>
      ),
      rightGroupMinGoalDifference: (
        <InterpretationToken
          chartActions={chartActions}
          highlightPoint={age9pointComparison}
          highlightSeries="COMPARISON"
          color={colors.comparison}
        >
          {formatPerc(1 - Math.abs(age9Values.COMPARISON))}
        </InterpretationToken>
      ),
    } as const;
  }, [
    age9Values.COMPARISON,
    age9Values.CURRENT,
    age9Values.SIMULATION_ACCESS,
    age9Values.SIMULATION_LEARNING,
    chartActions,
    comparisonGroupControl,
    countryControl,
    dataset,
    disparityGroupLabels,
    getCountryName,
    group,
    i18n,
    skill,
  ]);

  const handleSlideChange = (slideNumber: number) => {
    if (slideNumber === 0) {
      setHighlightedSeries("CURRENT");
      setTimeout(() => {
        setHighlightedSeries("COMPARISON");
        setTimeout(() => {
          setHighlightedSeries(undefined);
        }, 1000);
      }, 1000);
    }
    if (slideNumber === 1) {
      setHighlightedSeries("SIMULATION_ACCESS");
      setTimeout(() => setHighlightedSeries(undefined), 1000);
    } else if (slideNumber === 2) {
      setHighlightedSeries("SIMULATION_LEARNING");
      setTimeout(() => setHighlightedSeries(undefined), 1000);
    }
  };

  if (
    !countryControl ||
    !comparisonGroupControl ||
    !age9Values.CURRENT ||
    !age9Values.COMPARISON ||
    dataset.length === 0
  ) {
    return null;
  }

  return (
    <Carousel
      debug={false}
      FooterComponent={(footerProps) => (
        <CarouselFooter {...footerProps} onClose={requestClose} />
      )}
      onSlideChange={handleSlideChange}
    >
      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.groups.trajectories-slide-title"
            )`Interpretation`
          )}
        </SlideTitle>
        <Trans id="fig.learning.trajectories.interpretation.simulation.groups.trajectories-content">
          The blue trajectory shows the current rate of learning of {groupLeft}{" "}
          in
          {country}. The red trajectory shows the current rate of learning of
          {groupRight} in {country}. SDG 4.1.1 expects all children to have
          acquired foundational {skillName} skills by Grade 3, which typically
          corresponds to age 9. Currently, about {age9CountryValue} per cent of
          9-year-old {groupLeft} and {age9ComparisonValue} per cent of
          9-year-old {groupRight} can {skillVerb} at an age-appropriate level in{" "}
          {country}, a difference of about {groupDifference}. This difference is
          partly because {groupRight} have {groupDifferenceAdjective} grade
          attainment (they reach
          {groupDifferenceAdjective} grades in school) than {groupLeft}, and
          partly because they learn {groupDifferenceAdverb} per grade than{" "}
          {groupLeft}.
        </Trans>
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.groups.access-slide-title"
            )`Access simulation`
          )}
        </SlideTitle>
        {simulations.includes("SIMULATION_ACCESS") ? (
          <Trans id="fig.learning.trajectories.interpretation.simulation.groups.access-content">
            The simulations estimate how much different equality policies might
            close this gap. The {accessName}
            estimates what might happen if {groupLeft} in
            {country} attained the same grade, on average, as {groupRight}, but
            maintained their current rate of learning per grade. The equal
            access simulation results in {accessChange} of {accessDifference} in
            the proportion of 9-year-old {groupLeft} who can {skillVerb} at the
            age-appropriate level in {country}.
          </Trans>
        ) : (
          <ActivateSimulationSlide
            onActivate={() => handleActivate("SIMULATION_ACCESS")}
          />
        )}
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.groups.learning-slide-title"
            )`Learning simulation`
          )}
        </SlideTitle>
        {simulations.includes("SIMULATION_LEARNING") ? (
          <Trans id="fig.learning.trajectories.interpretation.simulation.groups.learning-content">
            The {learningName} estimates what might happen if {groupLeft} in{" "}
            {country}
            maintained their current grade attainment, but learned as much per
            grade as {groupRight} currently do. The simulation results in{" "}
            {learningChange} of {learningDifference} in the proportion of
            9-year-old {groupLeft} who can {skillVerb} at the age-appropriate
            level in {country}.
          </Trans>
        ) : (
          <ActivateSimulationSlide
            onActivate={() => handleActivate("SIMULATION_LEARNING")}
          />
        )}
      </Slide>

      <Slide>
        <SlideTitle>
          {i18n._(
            t(
              "fig.learning.trajectories.interpretation.simulation.groups.conclusion-slide-title"
            )`Conclusion`
          )}
        </SlideTitle>
        <Trans id="fig.learning.trajectories.interpretation.simulation.groups.conclusion-content">
          While focusing on inequality between {groupRight} and
          {groupLeft}, it is important to also analyze how far away more
          advantaged children are from achieving learning goals. At age 9, the
          relative difference in learning between {groupRight} and {groupLeft}{" "}
          is {groupDifference}. At the same time, {rightGroupMinGoalDifference}{" "}
          of {groupRight} are not meeting the minimum learning goal set by SDG
          4.1.1. This is critical context for understanding the potential, and
          potential limits, of different equality policies.
        </Trans>
      </Slide>
    </Carousel>
  );
};

export const Chart = ({ renderMode }: { renderMode: RenderMode }) => {
  const [chartData, setChartData] = useState<Data>([]);
  const ctx = React.useContext(ExplorerContext);
  const [state, mutate] = ctx.immer;
  const [, actions] = useFigureState();
  const i18n = useI18n();
  const env = useConfig();

  // Controls selections
  const interpretation = useControl("2-toggle-interpretation");
  const interpretationToggled = interpretation.length > 0;

  const view = useControl("0-view");
  const country = useControl("1-country");
  const group = useControl("2-groups");
  const comparisonCountry = useControl("2-comparison-country");
  const skill = useControl("0-vertical-axis");
  const countriesSimulations = useControl("1-simulations-countries");
  const disparitiesSimulations = useControl("1-simulations-disparities");
  const selectedView = useControl("0-view");
  const toggleInterpretationRef = useRef<HTMLButtonElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const getCountryName = useCallback(
    (country: CountryId | RegionId) =>
      getRegionOrCountryName({
        input: {
          country: country,
        },
        regions: env.regions,
        countries: env.countries,
      }),
    [env]
  );

  const getCountryInterpretationName = useCallback(
    (country: CountryId | RegionId) => {
      return country === ("lmic" as unknown as RegionId)
        ? i18n._(i18nLookup.averageLowLowerCountries)
        : getCountryName(country);
    },
    [getCountryName, i18n]
  );

  useEffect(() => {
    setChartData([]);
  }, [selectedView]);

  useEffect(() => {
    if (selectedView === "countries") {
      actions.setTitle(
        i18n._(
          t(
            "fig.learning.trajectories.explorer.simulations.countries.title"
          )`Policy simulations using learning trajectories: comparing ${getCountryName(
            countryIdIso.wrap(country)
          )} and ${getCountryName(countryIdIso.wrap(comparisonCountry))}`
        )
      );
    } else {
      actions.setTitle(
        i18n._(
          t(
            "fig.learning.trajectories.explorer.simulations.disparities.title"
          )`Policy simulations using learning trajectories: comparing groups inside ${getCountryName(
            countryIdIso.wrap(country)
          )}
          `
        )
      );
    }
  }, [actions, i18n, getCountryName, country, comparisonCountry, selectedView]);

  // Fetch data based on current controls selection
  useFetch({
    pause: false,
    fetch: useCallback(async () => {
      const shouldReturnEarly =
        (selectedView === "countries" && !(comparisonCountry && country)) ||
        comparisonCountry === country ||
        (selectedView === "disparities" && !country);

      if (shouldReturnEarly) {
        return [];
      }

      const simulations = await fetchSimulations(
        country,
        comparisonCountry,
        skill,
        group,
        selectedView
      );
      return parseSimulations(simulations);
    }, [comparisonCountry, country, group, selectedView, skill]),
    onFetch: () => {
      mutate((draft) => {
        draft.status = "fetching";
      });
    },
    onSuccess: (data) => {
      mutate((draft) => {
        draft.status = "data";
        setChartData(data);
      });
    },
    onError: (e) => {
      mutate((draft) => {
        draft.status = "error";
        draft.errorMessage = e instanceof Error ? e.message : `${e}`;
      });
    },
  });

  useEffect(() => {
    actions.setSelectionControl(
      Controls["2-toggle-interpretation"],
      {
        type: "MultiCheckbox",
        label: "",
        position: "TOOLBAR",
        selected: O.none,
      },
      [
        {
          value: "toggled",
          Component: (props: $IntentionalAny) => (
            <ToggleInterpretationButton
              animation={
                (view === "countries" &&
                  country !== "" &&
                  comparisonCountry !== "") ||
                (view === "disparities" && country !== "")
              }
              ref={toggleInterpretationRef}
              {...props}
            />
          ),
          label: i18n._(
            t(
              "fig.learning.trajectories.explorer.interpretation.toggle"
            )`Interpretation`
          ),
        },
      ]
    );
  }, [view, country, comparisonCountry, actions, i18n]);

  // Set controls
  useEffect(() => {
    actions.setSelectionControl(
      Controls["0-vertical-axis"],
      {
        type: "Radio",
        title: i18n._(verticalAxisControlLabel),
        selected: O.some("LIT"),
        position: "RIGHT",
      },
      verticalAxisControlItems(i18n)
    );
    actions.setSelectionControl(
      Controls["0-view"],
      {
        type: "Radio",
        title: i18n._(
          t(
            "fig.learning.trajectories.explorer.controls.simulations.view.title"
          )`Policy simulation for`
        ),
        selected: O.some("countries"),
        position: "LEFT",
      },
      viewControlItems(i18n)
    );

    actions.setSelectionControl(
      Controls["1-country"],
      {
        type: "SingleSelect",
        title: i18n._(countryControlTitle),
        label: i18n._(countryControlPlaceholder),
        selected: renderMode === "dropzone" ? O.some("BLR") : O.none,
        position: "LEFT",
      },
      state.controls.countries.filter(
        (country) =>
          !(
            selectedView === "disparities" &&
            COUNTRIES_EXCLUDED_DISPARITY_SIM.includes(country.value)
          )
      )
    );

    if (selectedView === "countries") {
      actions.clearControl(Controls["2-groups"]);
      actions.clearControl(Controls["1-simulations-disparities"]);

      actions.setSelectionControl(
        Controls["1-simulations-countries"],
        {
          type: "MultiCheckbox",
          selected: O.some(["SIMULATION_ACCESS", "SIMULATION_LEARNING"]),
          position: "RIGHT",
          ...simulationControlsLabels(i18n),
        },
        countriesSimulationControlItems(i18n)
      );

      actions.setSelectionControl(
        Controls["2-comparison-country"],
        {
          type: "SingleSelect",
          title: i18n._(comparisonCountryControlTitle),
          selected: renderMode === "dropzone" ? O.some("STP") : O.none,
          label: i18n._(countryControlPlaceholder),
          position: "LEFT",
        },
        state.controls.countries.filter((d) => d.value !== country)
      );
    } else {
      actions.clearControl(Controls["2-comparison-country"]);
      actions.clearControl(Controls["1-simulations-countries"]);

      actions.setSelectionControl(
        Controls["1-simulations-disparities"],
        {
          type: "MultiCheckbox",
          selected: O.some(["SIMULATION_ACCESS", "SIMULATION_LEARNING"]),
          position: "RIGHT",
          ...simulationControlsLabels(i18n),
        },
        disparitiesSimulationControlItems(i18n)
      );

      actions.setSelectionControl(
        Controls["2-groups"],
        {
          type: "Radio",
          title: i18n._(
            t(
              "fig.learning.trajectories.explorer.simulations.controls.groups.title"
            )`Groups`
          ),
          selected: O.some("WEALTH"),
          position: "LEFT",
        },
        disparityGroupsControlItems(i18n)
      );
    }
  }, [
    actions,
    state.controls.countries,
    selectedView,
    country,
    i18n,
    comparisonCountry,
    renderMode,
  ]);

  const dataForView = useMemo(
    () =>
      chartData.filter((d) => {
        const selectedSimulations =
          selectedView === "countries"
            ? countriesSimulations
            : disparitiesSimulations;

        return ["CURRENT", "COMPARISON", ...selectedSimulations].includes(
          d.series
        );
      }),
    [selectedView, countriesSimulations, disparitiesSimulations, chartData]
  );

  const currentSeries =
    selectedView === "countries"
      ? getCountryName(countryIdIso.wrap(country))
      : i18n._(
          GroupLabelLookup[
            disparitySimulationLookup[group as DisparityCategory].group
          ]
        );

  const comparisonSeries =
    selectedView === "countries"
      ? getCountryName(countryIdIso.wrap(comparisonCountry))
      : i18n._(
          GroupLabelLookup[
            disparitySimulationLookup[group as DisparityCategory]
              .comparisonGroup
          ]
        );

  const seriesAccessor = useCallback((d) => d.series, []);
  const [highlightedSeries, setHighlightedSeries] = useState<string>();
  const [lockActivePoint, setLockActivePoint] = useState<Data[number]>();

  const lineStyler = useMemo(
    () => makeLineStyler(highlightedSeries),
    [highlightedSeries]
  );

  const chartActions = useMemo(() => {
    return { setHighlightedSeries, setLockActivePoint };
  }, [setHighlightedSeries, setLockActivePoint]);

  const handleToggleInterpretation = () => {
    actions.updateSelectionControl(
      Controls["2-toggle-interpretation"],
      O.some(interpretationToggled ? [] : ["toggled"])
    );
  };

  return (
    <>
      <motion.div
        ref={chartContainerRef}
        style={{ position: "relative" }}
        initial={false}
        animate={{ opacity: state.status === "fetching" ? 0.25 : 1 }}
        transition={{ duration: 1 }}
      >
        <Popup
          open={interpretationToggled}
          container={chartContainerRef.current}
          anchor={toggleInterpretationRef.current}
          anchorPosition={{ horizontal: "end", vertical: "start" }}
          anchorOffset={{ horizontal: 20, vertical: -15 }}
          onClose={handleToggleInterpretation}
          style={{ width: 275 }}
        >
          {view === "countries" ? (
            <>
              {country === "" || comparisonCountry === "" ? (
                <EmptyInterpretation>
                  <SlideTitle>
                    <Trans id="fig.learning.trajectories.explorer.empty-title">
                      Interpretation
                    </Trans>
                  </SlideTitle>
                  <Trans id="fig.learning.trajectories.explorer.empty-select-countries">
                    Data interpretation is not available until filters are
                    selected. Select countries to get started.
                  </Trans>
                </EmptyInterpretation>
              ) : (
                <InterpretationSimulationCountries
                  dataset={dataForView}
                  getCountryName={getCountryInterpretationName}
                  chartActions={chartActions}
                  requestClose={() => handleToggleInterpretation()}
                />
              )}
            </>
          ) : view === "disparities" ? (
            <>
              {country === "" ? (
                <EmptyInterpretation>
                  <SlideTitle>
                    <Trans id="fig.learning.trajectories.explorer.empty-title">
                      Interpretation
                    </Trans>
                  </SlideTitle>
                  <Trans id="fig.learning.trajectories.explorer.empty-select-country">
                    Data interpretation is not available until filters are
                    selected. Select a country to get started.
                  </Trans>
                </EmptyInterpretation>
              ) : (
                <InterpretationSimulationDisparities
                  dataset={dataForView}
                  getCountryName={getCountryInterpretationName}
                  chartActions={chartActions}
                  requestClose={() => handleToggleInterpretation()}
                />
              )}
            </>
          ) : null}
        </Popup>
        <TrajectoriesChart<Data[number]>
          emptyChartMessage={
            view === "disparities"
              ? i18n._(
                  t(
                    "fig.learning.trajectories.explorer.empty-country"
                  )`Choose a country to get started`
                )
              : i18n._(
                  t(
                    "fig.learning.trajectories.explorer.empty-countries"
                  )`Choose a country and a comparison country to get started`
                )
          }
          height={M.chartHeight.m}
          dataset={dataForView}
          seriesAccessor={seriesAccessor}
          xAccessor={(d) => d.year}
          yAccessor={(d) => d.value}
          yAxisTitle={i18n._(yAxisTitleLookup[skill])}
          xAxisTitle={i18n._(xAxisTitleLookup["AGE"])}
          xAxisSubtitle={
            xAxisSubtitleLookup["AGE"]
              ? i18n._(xAxisSubtitleLookup["AGE"])
              : undefined
          }
          formatTooltipLabel={(d) => {
            const x = seriesLabelsLookup(currentSeries, comparisonSeries)[
              d.series as Series
            ];
            return typeof x === "string" ? x : i18n._(x);
          }}
          formatTooltipValue={(d) =>
            d.value === null
              ? i18n._(t("fig.label.data.not.available")`Not Available`)
              : format(".0%")(d.value)
          }
          rules={
            chartData.length > 0 ? [goalRule(i18n, "AGE", skill, true)] : []
          }
          lineStyle={lineStyler}
          backgroundSeriesFilter={(series: string | number) =>
            highlightedSeries ? series !== highlightedSeries : false
          }
          showColorLegend={true}
          formatLegendLabel={(series) =>
            legendLookup(currentSeries, comparisonSeries)[selectedView][
              series as Series
            ]
          }
          legendValues={[
            "COMPARISON",
            ...COUNTRY_SIMULATIONS_LEGEND.filter(
              (simulation) =>
                countriesSimulations.includes(simulation) ||
                disparitiesSimulations.includes(simulation)
            ),
            "CURRENT",
          ]}
          flipTooltip={true}
          lockActivePoint={lockActivePoint}
        />
      </motion.div>
    </>
  );
};

export default withFigureIOExplorer({
  metadata,
  Chart,
  size: "main",
  csv: require("../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER.zip"),
  xlsx: require("../data/data_learning_trajectories_fig_TRAJECTORIES_EXPLORER.xlsx"),
});

const legendLookup = (
  currentSeries: string,
  comparisonSeries: string
): Record<View, Record<Series, React.ReactNode>> => ({
  countries: {
    SIMULATION_ACCESS: (
      <Trans>
        Access simulation (100% grade attainment in {currentSeries} * current
        learning per grade)
      </Trans>
    ),
    SIMULATION_LEARNING: (
      <Trans>
        Learning simulation (grade attainment in {currentSeries} * learning per
        grade in {comparisonSeries})
      </Trans>
    ),
    CURRENT: <Trans> Current learning in {currentSeries}</Trans>,
    COMPARISON: <Trans> Current learning in {comparisonSeries}</Trans>,
  },
  disparities: {
    SIMULATION_ACCESS: (
      <Trans>
        Equal access simulation (if {currentSeries.toLowerCase()} got as much
        schooling as
        {comparisonSeries.toLowerCase()})
      </Trans>
    ),
    SIMULATION_LEARNING: (
      <Trans>
        Equal learning simulation (if {currentSeries.toLowerCase()} learned as
        much per grade as
        {comparisonSeries.toLowerCase()})
      </Trans>
    ),
    CURRENT: <Trans> Current learning of {currentSeries.toLowerCase()}</Trans>,
    COMPARISON: (
      <Trans> Current learning of {comparisonSeries.toLowerCase()}</Trans>
    ),
  },
});

const seriesLabelsLookup = (
  currentSeries: string,
  comparisonSeries: string
): Record<Series, string | MessageDescriptor> => ({
  SIMULATION_ACCESS: t(
    "fig.learning.trajectories.explorer.tooltip.label.simulationAccess"
  )`Access simulation`,
  SIMULATION_LEARNING: t(
    "fig.learning.trajectories.explorer.tooltip.label.simulationLearning"
  )`Learning simulation`,
  CURRENT: currentSeries,
  COMPARISON: comparisonSeries,
});

const parseSimulations = (
  rawSimulations: Awaited<ReturnType<typeof fetchSimulations>>
) => {
  return pipe(
    Data.decode(rawSimulations),
    E.fold(
      (e) => {
        console.warn("Error while fetching simulations", e);
        throw new Error(`Invalid data ${e instanceof Error ? e.message : e}`);
      },
      (x) => x
    )
  );
};

const fetchSimulations = async (
  selectedCountry: string,
  selectedComparisonCountry: string,
  selectedSkill: SKILL,
  selectedGroup: DisparityCategory,
  view: View
) => {
  if (view === "countries") {
    const { getCountrySimulations } = await explorerAPI.query({
      getCountrySimulations: [
        {
          country: selectedCountry,
          comparisonCountry: selectedComparisonCountry,
          skill: selectedSkill,
        },
        everything,
      ],
    });

    return getCountrySimulations;
  }

  const { getEqualitySimulations } = await explorerAPI.query({
    getEqualitySimulations: [
      {
        country: selectedCountry,
        group: disparitySimulationLookup[selectedGroup].group,
        comparisonGroup:
          disparitySimulationLookup[selectedGroup].comparisonGroup,
        skill: selectedSkill,
      },
      everything,
    ],
  });

  return getEqualitySimulations;
};
