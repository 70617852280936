import css from "@emotion/css";
import { nanoid } from "nanoid";
import React from "react";
import * as M from "../materials";

export interface Option {
  value: string;
  label: string;
}

const RadioSelectBox = ({
  title,
  options,
  selected,
  onChange,
}: {
  title: string;
  options: Option[];
  selected: string;
  onChange: (v: string) => void;
}) => {
  const id = `radio-form-${nanoid(5)}`;

  const handler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <h4
        css={css`
          ${M.fontHeading4}
          margin-bottom: ${M.spacing.base8(0.5)};
        `}
      >
        {title}
      </h4>
      <div
        css={css`
          background-color: ${M.grayscalePalette[2]};
          border: 1px solid ${M.grayscalePalette[4]};
          border-radius: ${M.spacing.base8(0.5)};
          ${M.fontChartGroupTitle}
          margin-bottom: ${M.spacing.base8(2)}
        `}
      >
        <div>
          {options.map(({ value, label }) => {
            const isSelected = value === selected;
            return (
              <div
                key={value}
                css={css`
                  width: 100%;
                  display: flex;
                  align-items: center;
                  border-radius: ${M.spacing.base8(0.5)};
                  padding: ${M.spacing.base8(0.5)} ${M.spacing.base8(1)};
                  &:hover {
                    background-color: ${M.grayscalePalette[3]};
                  }
                `}
              >
                <input
                  type="radio"
                  id={`radio-${value}`}
                  value={value}
                  checked={isSelected}
                  name={id}
                  onChange={handler}
                />
                <label
                  htmlFor={`radio-${value}`}
                  css={css`
                    width: 100%;
                    margin-left: ${M.spacing.base8(1)};
                    margin-right: ${M.spacing.base8(1)};
                    color: ${isSelected ? M.unescoMarineBlue : M.blackText};
                  `}
                >
                  {label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RadioSelectBox;
