import { FigureMetadata } from "../components/figure";

export const metadata: FigureMetadata = {
  id: "TRAJECTORIES_SIMULATION_LMIC",
  title: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.title",
    defaults: `Simulations of the relative impact of achieving universal schooling and higher learning per grade in low- and lower-middle-income countries`,
  },
  caption: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.caption",
    defaults: `Descriptive and simulated learning trajectories for 10 low- and lower-middle-income countries showing the percentage of children (aged 7–14 years) who have mastered foundational skills, typically at the Grade 2–3 level. This is below the minimum proficiency level defined by SDG global indicator 4.1.1. Trajectories by age include all children (whether they were in school at the time of the survey, had dropped out or had never attended school).`,
  },
  source: /*i18n*/ {
    id: "fig.learning.trajectories.TRAJECTORIES_SIMULATION_LMIC.source",
    defaults: `UNICEF MICS6 data based on simulation methodology and analysis by RISE.`,
  },
};
