import React from "react";
import { debounce } from "throttle-debounce";

const IS_CLIENT = typeof window === "object";
const DEFAULT_WINDOW_SIZE = {
  width: 1024,
  height: 768,
};

export function useWindowSize() {
  const getSize = React.useCallback(
    () =>
      IS_CLIENT
        ? { width: window.innerWidth, height: window.innerHeight }
        : DEFAULT_WINDOW_SIZE,
    []
  );

  const [windowSize, setWindowSize] = React.useState(getSize);

  const handleResize = React.useMemo(() => {
    return debounce(250, () => setWindowSize(getSize()), { atBegin: false });
  }, [setWindowSize, getSize]);

  React.useEffect(() => {
    if (!IS_CLIENT) {
      return void 0;
    }

    window.addEventListener("orientationchange", handleResize);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("orientationchange", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, [getSize, handleResize]);

  return windowSize;
}
