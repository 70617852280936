import { css } from "@emotion/core";
import { I18n, MessageDescriptor } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import * as M from "../materials";

import { mkStyles } from "hooks";
import React, { forwardRef, useCallback } from "react";
import { colors } from "../figures/TRAJECTORIES_SIMULATION_LMIC";
import { useEffect } from "react";
import { Button } from "components/button";
import SvgIcQuestionMark16Dp from "icons/SvgIcQuestionMark16Dp";
import { Data } from "figures/TRAJECTORIES_EXPLORER_SIMULATION";
import { Series, CountryId, RegionId } from "domain/explorer";
import { DisparityCategory, DisparityGroup } from "domain/disparities";
import SvgIcArrowLeft from "icons/SvgIcArrowLeft";
import useForwardRef from "../hooks/useForwardRef";
import { format } from "d3-format";

type ChartActions = {
  setHighlightedSeries: (series: string | undefined) => void;
  setLockActivePoint: (datapoint: Data[number] | undefined) => void;
};

const DefaultVal = ({ children }: { children: React.ReactNode }) => {
  return <span style={{ color: "hotpink" }}>{children}</span>;
};

export const useSlideStyles = mkStyles((theme) => ({
  slideTitle: css`
    ${M.fontHeading4};
    margin-bottom: 0.5rem;
  `,
  slide: css`
    ${M.fontInterpretation}
    line-height: 1.5;
  `,
  footerButton: css`
    min-width: auto !important;
    padding-left: 1rem;
    padding-right: 1rem;

    @media ${theme.breakpoints.down("m")} {
      font-size: 14px;
      line-height: 20px;
    }
  `,
}));

export const defaultValues = {
  country: <DefaultVal>Switzerland</DefaultVal>,
  comparisonCountry: <DefaultVal>Italy</DefaultVal>,
  age9CountryValue: <DefaultVal>12%</DefaultVal>,
  age9ComparisonValue: <DefaultVal>15%</DefaultVal>,
  countryDifference: <DefaultVal>3%</DefaultVal>,
  skillVerb: <DefaultVal>read</DefaultVal>,
  skillName: <DefaultVal>reading</DefaultVal>,

  accessName: (
    <strong style={{ color: colors.accessSimulation }}>
      Access Simulation
    </strong>
  ),
  accessChange: <DefaultVal>an increase</DefaultVal>,
  accessDifference: <DefaultVal>12</DefaultVal>,

  learningName: (
    <strong style={{ color: colors.learningSimulation }}>
      Learning Simulation
    </strong>
  ),
  learningChange: <DefaultVal>a decrease</DefaultVal>,
  learningChangeAdverb: <DefaultVal>greater</DefaultVal>,
  learningDifference: <DefaultVal>14</DefaultVal>,
} as const;

export const Highlight = ({ children }: { children: React.ReactNode }) => (
  <span>{children}</span>
);

const formatRoundPerc = format(".0%");
const formatRound = format("d");

export const formatPerc = (d: number | null) => formatRoundPerc(d || 0);
export const formatPercentagePoints = (d: number | null, i18n: I18n) => {
  return `${formatRound(d !== null ? d * 100 : 0)} ${i18n._(
    t("percentage points")`percentage points`
  )}`;
};

const messageDescriptors = <T extends { [P in keyof T]: MessageDescriptor }>(
  x: T
): {
  [P in keyof T]: MessageDescriptor;
} => x;

export const i18nLookup = messageDescriptors({
  increase: t("fig.learning.trajectories.interpretation.increase")`an increase`,
  decrease: t("fig.learning.trajectories.interpretation.decrease")`a decrease`,
  noChange: t("fig.learning.trajectories.interpretation.no-change")`no change`,
  greater: t("fig.learning.trajectories.interpretation.greater")`greater`,
  lower: t("fig.learning.trajectories.interpretation.lower")`lower`,
  higher: t("fig.learning.trajectories.interpretation.higher")`higher`,
  less: t("fig.learning.trajectories.interpretation.less")`less`,
  more: t("fig.learning.trajectories.interpretation.more")`more`,
  averageLowLowerCountries: t(
    "fig.learning.trajectories.interpretation.averageLowLowerCountries"
  )`the average low and lower-middle income country`,
  "skillName.LIT": t(
    "fig.learning.trajectories.interpretation.skillName.LIT"
  )`reading`,
  "skillName.NUM": t(
    "fig.learning.trajectories.interpretation.skillName.NUM"
  )`numeracy`,
  "skillVerb.LIT": t(
    "fig.learning.trajectories.interpretation.skillVerb.LIT"
  )`read`,
  "skillVerb.NUM": t(
    "fig.learning.trajectories.interpretation.skillVerb.NUM"
  )`do basic math`,
} as const);

export const makeDisparityGroupLabels = (
  i18n: I18n
): Record<DisparityCategory, [string, string]> => ({
  WEALTH: [
    i18n._(
      t("trajectories.disparities.WEALTH.left")`children from poor families`
    ),
    i18n._(
      t("trajectories.disparities.WEALTH.right")`children from wealthy families`
    ),
  ],
  GENDER: [
    i18n._(t("trajectories.disparities.GENDER.left")`girls`),
    i18n._(t("trajectories.disparities.GENDER.right")`boys`),
  ],
  GEOGRAPHY: [
    i18n._(
      t("trajectories.disparities.GEOGRAPHY.left")`children from rural areas`
    ),
    i18n._(
      t("trajectories.disparities.GEOGRAPHY.right")`children from urban areas`
    ),
  ],
});

export const InterpretationToken = ({
  children,
  highlightSeries,
  highlightPoint,
  chartActions,
  color,
}: {
  children: React.ReactNode;
  highlightSeries?: Series | DisparityGroup;
  highlightPoint?: Data[number] | undefined;
  chartActions: ChartActions;
  color?: string;
}) => {
  const handleFocus = useCallback(() => {
    if (highlightSeries) {
      chartActions.setHighlightedSeries(highlightSeries);
    }
    if (highlightPoint) {
      chartActions.setLockActivePoint(highlightPoint);
    }
  }, [highlightSeries, highlightPoint, chartActions]);

  const handleBlur = useCallback(() => {
    chartActions.setHighlightedSeries(undefined);
    chartActions.setLockActivePoint(undefined);
  }, [chartActions]);

  return (
    <span
      css={css`
        --background: rgba(0, 0, 0, 0.075);
        &:hover {
          transition:
            background-color 0.25s ease-out,
            box-shadow 0.25s ease-out;
          cursor: pointer;
          border-radius: 4px;
          background: var(--background);
          box-shadow: 0 0 0 1px var(--background);
        }
      `}
      onFocus={handleFocus}
      onMouseOver={handleFocus}
      onBlur={handleBlur}
      onMouseOut={handleBlur}
      style={{ fontWeight: "bold", color }}
    >
      {children}
    </span>
  );
};

export const CarouselFooter = ({
  paginate,
  page,
  count,
}: {
  paginate: (n: number) => void;
  page: number;
  count: number;
  onClose: () => void;
}) => {
  const styles = useSlideStyles();
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {page === 0 ? (
        <div />
      ) : (
        <Button
          rounded
          inverted
          inline
          onClick={() => paginate(-1)}
          css={css`
            border-width: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & > svg:first-child {
              margin-right: 0.5rem;
            }
            &:hover,
            &:focus {
              border-width: 0;
              background-color: transparent;
            }
          `}
        >
          <SvgIcArrowLeft />
          <Trans id="Back">Back</Trans>
        </Button>
      )}
      {page !== count - 1 ? (
        <Button
          rounded
          css={styles.footerButton}
          inline
          onClick={() => paginate(+1)}
        >
          <Trans id="next">Next</Trans>
        </Button>
      ) : null}
    </div>
  );
};

/**
 * Custom renderer for the MultiCheckbox control used to
 * show the Data interpretation button above the chart.
 *
 * The button wiggles when it is in the viewport.
 */
export const ToggleInterpretationButton = forwardRef<
  HTMLButtonElement,
  {
    value: string;
    isSelected: boolean;
    id: string;
    handler: (event: { target: { value: string } }) => void;
    label: string;
  } & {
    animation: boolean;
  }
>(({ handler, label, value, isSelected, animation }, ref) => {
  const localRef = useForwardRef<HTMLButtonElement>(ref);
  useEffect(() => {
    if (!localRef.current) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.intersectionRatio > 0) {
        localRef.current?.classList.add("inView");
      } else {
        localRef.current?.classList.remove("inView");
      }
    });
    observer.observe(localRef.current);
    return () => {
      observer.disconnect();
    };
  }, [localRef]);
  return (
    <Button
      ref={localRef}
      className={isSelected ? `opened` : undefined}
      css={css`
        margin: -3px;
        padding: 5px;
        display: flex;
        width: calc(100% + 1rem);
        justify-content: center;

        &.inView {
          animation: ${animation !== false
            ? "pulse 1s ease-in-out 0s 20 normal forwards"
            : "none"};
        }

        &[data-focus] {
          animation: none;
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(0, 117, 209, 0.5);
          }

          70% {
            box-shadow: 0 0 0 10px rgba(0, 117, 209, 0);
          }

          100% {
            box-shadow: 0 0 0 0 rgba(0, 117, 209, 0);
          }
        }
      `}
      rounded
      data-focus={isSelected ? "focused" : undefined}
      onClick={() => handler({ target: { value } })}
    >
      <SvgIcQuestionMark16Dp
        css={css`
          margin-right: 0.5rem;
        `}
      />
      {label}
    </Button>
  );
});

export const EmptyInterpretation = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      css={css`
        ${M.fontInterpretation}
      `}
    >
      {children}
    </div>
  );
};

export type InterpretationSlideProps = {
  dataset: Data;
  getCountryName: (isoCode: CountryId | RegionId) => string;
  chartActions: ChartActions;
  requestClose: () => void;
};

export const SlideTitle = ({ children }: { children: React.ReactNode }) => {
  const styles = useSlideStyles();
  return <h4 css={styles.slideTitle}>{children}</h4>;
};

export const Slide = ({ children }: { children: React.ReactNode }) => {
  const styles = useSlideStyles();
  return <div css={styles.slide}>{children}</div>;
};
